import getConfig from 'next/config'

const {publicRuntimeConfig} = getConfig()

export const SITE_NAME = publicRuntimeConfig.siteName
export const ENVIROMENT = publicRuntimeConfig.environment
export const ADMIN_EMAIL = publicRuntimeConfig.adminEmail

export const LOGIN_URL = '/users/login'
export const LOGOUT_URL = '/users/logout'

export const LANGUAGES = [
    {code: 'es-ar', name: 'Español'},
    {code: 'en-us', name: 'English'},
    {code: 'pt-br', name: 'Português'},
]
export const LANGUAGE_CODE = 'es-ar'
export const CURRENCY_CODE = 'USD'
export const MAX_DECIMALS = 2

export const TIMEZONE = -3
export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss'

export const TAX_ID_MASK: {[T: string]: (string | RegExp)[]} = {
    AR_CUIT: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/],
    CH_RUT: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/],
    UY_RUT: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    BR_CNPJ: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    USA_EIN: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    OTHER: Array(30).fill(/\d/),
}
