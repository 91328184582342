import {ApolloError} from '@apollo/client'
import {Trans} from '@lingui/macro'
import {Button} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Alert, AlertTitle} from '@material-ui/lab'
import {FC} from 'react'
import {ADMIN_EMAIL, ENVIROMENT} from 'settings'

interface GraphQLErrorsProps {
    error: ApolloError
    refetch?: () => any
    showDetail?: boolean
}

export const GraphQLErrors: FC<GraphQLErrorsProps> = ({error, refetch, showDetail}) => {
    const classes = useStyles()
    const isDev = ![
        'qa', 'staging', 'production',
    ].includes(ENVIROMENT)

    const refetchButton = refetch && (
        <Button color='inherit' size='small' onClick={() => refetch()}>
            Retry
        </Button>
    )

    return (
        <div className={classes.root}>
            {isDev || showDetail
                ? (
                    <>
                        {error.graphQLErrors.map(({message}) => (
                            <Alert key={message} severity='error' action={refetchButton}>
                                <AlertTitle>GraphQL error</AlertTitle>
                                {message}
                            </Alert>
                        ))}
                        {error.networkError && (
                            <Alert severity='error' action={refetchButton}>
                                <AlertTitle>Network error</AlertTitle>
                                {error.networkError.message}
                            </Alert>
                        )}
                    </>
                )
                : (
                    <Alert severity='error' action={refetchButton}>
                        <AlertTitle>Error</AlertTitle>
                        <Button color='inherit' size='small' onClick={() => window.open(`mailto:${ADMIN_EMAIL}`)}>
                            <Trans>
                                Please contact the website admin {ADMIN_EMAIL}
                            </Trans>
                        </Button>
                    </Alert>
                )}
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginBottom: theme.spacing(2),
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}))
